video {
    background: #ccc;
    width: 100%;
    height: 50vh;
}

.info {
    height: 50vh;
}

.error {
    text-align: center;
    padding: 2em;
    color: maroon;
}

.qr-item {
    display: flex;
    padding: .5em;
}

.qr-item div {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    padding: .3em .5em .3em 0;
}

.qr-item button {
    width: 30px;
}

#video-container.example-style-1 .scan-region-highlight-svg,
#video-container.example-style-1 .code-outline-highlight {
    stroke: #19b907 !important;
}

#video-container.example-style-2 {
    position: relative;
    width: max-content;
    height: max-content;
    overflow: hidden;
}

#video-container.example-style-2 .scan-region-highlight {
    border-radius: 30px;
    outline: rgba(0, 0, 0, .25) solid 50vmax;
}

#video-container.example-style-2 .scan-region-highlight-svg {
    display: none;
}

#video-container.example-style-2 .code-outline-highlight {
    stroke: rgba(255, 255, 255, .5) !important;
    stroke-width: 15 !important;
    stroke-dasharray: none !important;
}
